.home {
    display: grid;
}

.round-image {
    justify-self: center;
    width: 25%;
    border-radius: 50%;
}

@media (max-width: 768px) {
    .round-image {
        width: 75%;
    }
}
