.contact {
    text-align: center;

    ul {
        list-style: none;
        padding: 0;
    }

    li {
        display: inline-block;
        padding: 1em;
    }

    i {
        vertical-align: middle;
    }

    p {
        font-weight: bold;

        &:nth-child(2) {
            color: $secondary-color;
        }

        &:nth-child(3) {
            text-decoration: underline;
        }
    }
}

.fa-stack.big {
    font-size: 2.5em;
}