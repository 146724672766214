$first-color: #139705;
$secondary-color: #1fc20e;

@mixin link-style {
    a {
        color: inherit;
        display: block;
        text-decoration: none;

        &:hover {
            color: $secondary-color;
        }

        &.active {
            border-bottom: 4px solid $first-color;
            border-radius: 2px;
        }
    }
}

html {
    font-family: 'Roboto', sans-serif;
    overflow: scroll;
    font-size: 100%;
}

p {
    font-size: 1em;
    margin-bottom: 1.4rem;
}

body {
    background-color: rgb(239, 239, 239);
    color: rgb(50, 50, 50);
    line-height: 1.4;
}

#root {
    display: grid;
}

main {
    min-height: 80vh;
    display: grid;

    h1 {
        font-size: 3.5em;
        text-align: center;
        margin-bottom: 3.5rem;
    }
}

.screenshot {
    justify-self: center;
    width: 50%;

    img {
        width: 100%;
        -webkit-filter: blur(3px);
        filter: blur(3px);
        transition-duration: 0.5s;

        &:hover {
            -webkit-filter: none;
            filter: none;
            transition-duration: 0.5s;
        }
    }
}
