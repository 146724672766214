@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');

header {
    display: grid;
    padding: 0 2em 0 2em;

    h1 {
        grid-row: 1 / 1;
        margin: 0;
        align-self: center;
        font-family: 'Press Start 2P', sans-serif;

        @include link-style;
    }
}

nav {
    grid-row: 1 / 1;
    justify-self: end;
    align-self: end;

    ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    li {
        display: inline-block;

        @include link-style;

        a {
            padding: 1em !important;
        }
    }
}

@media (max-width: 768px) {
    header {
        h1 {
            grid-row: 1 / 2;
            justify-self: center;
            align-self: center;
            text-align: center;
            margin: 1em 0 1em 0;
        }
    }

    nav {
        grid-row: 2 / 3;
        justify-self: center;
        align-self: center;
    }
}
