footer {
    max-width: 960px;
    display: flex;
    justify-content: center;
    justify-self: center;
    gap: 1em;
    border-top: 4px solid $first-color;
    border-radius: 2px;
    width: 100%;
    padding-top: 1em;

    @include link-style;

    i {
        font-size: 2em;
    }
}

@media (max-width: 768px) {
    footer {
        i {
            font-size: 3em;
            margin-bottom: 0.5em;
        }
    }
}
